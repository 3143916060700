<template>
    <vs-card>
        <div
          slot:header
          class="flex flex-no-wrap w-full justify-end items-center px-5"
          vs-w="12"
        >
        <!-- <h4>Organisations</h4> -->
        <div class="mr-2">
            <vs-button class=" mr-2" @click="redirectPreviewRoute">Preview</vs-button>
            <vs-button @click="updateStoreAlert">Save</vs-button>
        </div>
      </div>
        <vs-form autocomplete="off">
            <div class=" p-5">
                <div class="w-full my-5">
                  <span class=" text-base"><label>Alert Status:</label><label class=" text-primary">{{ alert.active ? " Published" : " Unpublished"}}</label></span>
                  <br/>
                  <vs-switch class=" my-2" v-model="alert.active">
                      <span slot="on">On</span>
                      <span slot="off">Off</span>
                    </vs-switch>
                </div>
                <div class="w-full my-5">
                    <vs-input
                        :success="!errors.first('title') && alert.title != ''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        :danger="errors.first('title') ? true : false"
                        v-validate="'required|max:150'"
                        name="title"
                        data-vv-as="title"
                        label="Alert Title"
                        class="w-full"
                        :danger-text="errors.first('title')"
                        v-model="alert.title"
                    />
                </div>
                <div class="w-full my-5">
                  <label>Alert Description</label>
                  <vs-textarea
                      :success="!errors.first('description') && alert.description != ''"
                      val-icon-success="done"
                      val-icon-danger="error"
                      :danger="errors.first('description') ? true : false"
                      v-validate="'required'"
                      name="description"
                      data-vv-as="description"
                      class="w-full"
                      :danger-text="errors.first('description')"
                      v-model="alert.description"
                  />
                </div>
                <div class=" my-5">
                  <label>Alert type</label>
                  <br/>
                  <vs-radio v-model="alert.alert_type" vs-value="primary" color="primary">Notice</vs-radio>
                  <br/>
                  <!-- <vs-radio v-model="alert.alert_type" vs-value="success" color="success">Success</vs-radio> -->
                  <!-- <br/> -->
                  <vs-radio v-model="alert.alert_type" vs-value="warning" color="warning">Attention</vs-radio>
                  <br/>
                  <vs-radio v-model="alert.alert_type" vs-value="danger" color="danger">Urgent</vs-radio>
                </div>
              
                <div>
                  <h5>Alert Image</h5>
                </div>
              
                <div class="w-full my-5">
                    <label>Desktop Image - Optional</label>
                    <div v-if="!alert.images.desktop">
                        <input type="file" ref="fileInputDesktop" hidden @change="handleFileChange($event, 'desktop')" accept="image/*" />
                        <vs-button @click="uploadDesktopImage" >Upload Image</vs-button>
                    </div>
                    <div v-else>
                      <img width="50%" v-if="alert.images.desktop" :src="alert.images.desktop" alt class="img-fluid" />
                    </div>
                </div>
                <div class="w-full my-5">
                    <label>Mobile Image - Optional</label>
                    <div v-if="!alert.images.mobile">
                        <input type="file" ref="fileInputMobile" hidden @change="handleFileChange($event, 'mobile')" accept="image/*" />
                        <vs-button @click="uploadMobileImage" >Upload Image</vs-button>
                    </div>
                    <div v-else>
                      <img v-if="alert.images.mobile" :src="alert.images.mobile" alt class="img-fluid" />
                    </div>
                </div>


                <div class=" mt-10">
                  <h5>Display Settings</h5>
                </div>
                <div class="w-full my-5">
                  <div class=" my-5 flex justify-between">
                  <div>
                    <label class=" text-lg">General Alert</label>
                    <!-- <label class=" text-lg">Homepage</label> -->
                    <br/>
                    <span>Turning on this button will override all the selected pages below on both brand and category.</span>
                    <!-- <span>Display this alert on the homepage. Please note we should only have 1 homepage banner active.</span> -->
                  </div>
                  <vs-switch v-model="alert.homepage_display">
                    <span slot="on">On</span>
                    <span slot="off">Off</span>
                  </vs-switch>
                </div>
                  <div class=" flex justify-between">
                    <div>
                      <label class=" text-lg">Brand Pages</label>
                      <br/>
                      <span>Select which brand pages to display the alert on</span>
                    </div>
                    <vs-switch v-model="alert.brands.active">
                      <span slot="on">On</span>
                      <span slot="off">Off</span>
                    </vs-switch>
                  </div>
                    <div>
                        <multiselect 
                            v-model="alert.brands.ids" 
                            tag-placeholder="Add this as new tag" 
                            placeholder="Search or add a tag" 
                            label="name" 
                            track-by="_id" 
                            :options="brandList" 
                            :multiple="true" 
                            :taggable="true"
                            searchable
                            :loading="brandLoading"
                            :close-on-select="false"
                            >
                            <!-- @search-change="getAllBrandsList" -->
                        </multiselect>
                    </div>
                    <div class=" mt-5 mb-10 flex justify-between">
                        <vs-checkbox v-model="alert.brands.product_display">Display this banner on every product in this brand</vs-checkbox>
                    </div>
                </div>
                <div class="w-full my-5">
                  <div class=" flex justify-between">
                    <div>
                      <label class=" text-lg">Category Pages</label>
                      <br/>
                      <span>Select which category pages to display the alert on</span>
                    </div>
                    <vs-switch v-model="alert.categories.active">
                      <span slot="on">On</span>
                      <span slot="off">Off</span>
                    </vs-switch>
                  </div>
                  <div>
                      <multiselect 
                          v-model="alert.categories.ids" 
                          tag-placeholder="Add this as new tag" 
                          placeholder="Search or add a tag" 
                          label="name" 
                          track-by="_id" 
                          :options="categoryList" 
                          :multiple="true" 
                          :taggable="true"
                          :loading="categoryLoading"
                          :close-on-select="false"
                          >
                          <!-- @search-change="asyncFind" -->
                      </multiselect>
                  </div>
                   <div class=" mt-5 mb-10 flex justify-between">
                        <vs-checkbox v-model="alert.categories.product_display">Display this banner on every product in this category</vs-checkbox>
                        
                    </div>
                </div>

                <div class=" my-5">
                    <label class=" text-lg">Checkout Settings</label>
                    <br/>
                    <vs-radio key="0" v-model="alert.checkout_display" :vs-value="1">Always display this branner at checkout</vs-radio>
                    <br/>
                    <vs-radio key="2" v-model="alert.checkout_display" :vs-value="2">Only display this banner at checkout if any of the brand, or category products are in the cart</vs-radio>
                    <br/>
                    <vs-radio key="2" v-model="alert.checkout_display" :vs-value="0">Don't display this at checkout</vs-radio>
                </div>
                <div>
                  <vs-button color="danger" @click="handleDeleteAlert">Delete Alert</vs-button>
                </div>
            </div>
        </vs-form>
    </vs-card>
  </template>


  <script>
  import axios from "@/axios";
  import { mapActions } from "vuex";
  import { Validator } from "vee-validate";
  import Multiselect from 'vue-multiselect';
  import 'vue-multiselect/dist/vue-multiselect.min.css';
  import _ from 'lodash';
  
  const dict = {
    custom: {
      consultationFee: {
        regex: "The consultation fee field must be a number",
      },
      email: {
        regex: "The Monthly Treatment Invoice Email must be a valid email"
      }
    },
  };
  Validator.localize("en", dict);
  
  export default {
    components: {
        Multiselect,
    },
    data: () => ({
      alertId: "",
      brandList: [],
      categoryList: [],
      alert: {
          title: "",
          description: "",
          alert_type: "",
          images: {
            mobile: "",
            desktop: ""
          }, 
          brands: {
            active: false,
            product_display: false,
            ids: []
          },
          categories: {
            active: false,
            product_display: false,
            ids: []
          },
          checkout_display: null,
          homepage_display: null,
          active: false
      },
      imageType: '',
      brandLoading: false,
      categoryLoading: false
    }),
    methods: {
      ...mapActions("storeAlertNotification", [
        "getStoreAlertNotificationDetails",
        "updateStoreAlertNotificationDetails",
        "deleteStoreAlertNotificationById"
      ]),
      ...mapActions("brand", ["getBrandAll",]),
      ...mapActions("category", ["getAllCategory",]),
      async getAllBrandsList() {
        try {
          this.brandLoading = true
          const res = await this.getBrandAll();
          this.brandList = res.data
          this.brandLoading = false
        } catch (error) {
          this.brandLoading = false
          console.log(error)
        }
      },
      async fetchAllCategoryList() {
        try {
          this.categoryLoading = true
          const res = await this.getAllCategory();
          this.categoryList = JSON.parse(JSON.stringify(res.data));
          this.categoryLoading = false
        } catch (error) {
          this.categoryLoading = false
          console.log(error)
        }
      },

      async fetchAlertDetails() {
        try {
          const res = await this.getStoreAlertNotificationDetails(this.alertId)
          const tempData = {...res.data.data[0]};
          if(tempData.alert_type === "success") tempData.alert_type = "primary"
          if(tempData.alert_type === "error") tempData.alert_type = "danger"
          tempData.brands = {
            active: tempData.brands.active,
            product_display: tempData.brands.product_display,
            ids: tempData.brands.ids.map( (brandId) => {
              return  _.filter(this.brandList, function(brand) { return brand._id === brandId } )[0]
            })
          }
          tempData.categories = {
            active: tempData.categories.active,
            product_display: tempData.categories.product_display,
            ids: tempData.categories.ids.map( (categoryId) => {
              return  _.filter(this.categoryList, function(category) { return category._id === categoryId } )[0]
            })
          }
          tempData.checkout_display = JSON.stringify(tempData.checkout_display)
          console.log(tempData, "tempData")
          this.alert = tempData
          this.$vs.loading.close();

        } catch (error) {
          this.$vs.loading.close();
          console.log(error.message)
        }
      },
      handleFileChange(event, type) {
        this.imageType = type;
        this.uploadImage(event.target.files[0], "alertMobileImage");
      },
      
      redirectPreviewRoute() {
        this.$router.push({
          name: "AlertPreview",
          params: {
            alertId: this.alertId
          }
        });
      },
      uploadDesktopImage() {
          this.$refs.fileInputDesktop.click();
      },
      uploadMobileImage() {
          this.$refs.fileInputMobile.click();
      },
      async updateStoreAlert () {
        // }
        
          // let isValid = await this.$validator.validate();
          // if (isValid) {
            try {
              const tempData = {...this.alert};
              tempData.brands = {
                    active: this.alert.brands.active,
                    product_display: this.alert.brands.product_display,
                    ids: this.alert.brands.ids.map((brand) => brand._id)
              }
              tempData.categories = {
                    active: this.alert.categories.active,
                    product_display: this.alert.categories.product_display,
                    ids: this.alert.categories.ids.map((category) => category._id)
              }
              tempData.checkout_display = parseInt(tempData.checkout_display)
              if(tempData.alert_type === "primary") tempData.alert_type = "success"
              if(tempData.alert_type === "danger") tempData.alert_type = "error"
              delete tempData.createdAt
              delete tempData.updatedAt
              delete tempData._id
              const res = await this.updateStoreAlertNotificationDetails({id: this.alertId, data: tempData});
              this.$vs.notify({
                title: "Alert Notification",
                text: "Alert notification created successfully.",
                color: "success",
              });
              // this.$router.push({
              //   name: "AlertManagement"
              // })

            } catch (err) {
              console.log(err)
              this.$vs.notify({
                title: "Alert notification",
                text: "Creation of alert notification failed.",
                color: "danger",
              });
            }
          // }
      },
      async handleDeleteAlert() {
        try {
          const res = await this.deleteStoreAlertNotificationById({id: this.alertId})
          this.$vs.notify({
            title: "Delete Alert Notification",
            text: "Alert notification successfully deleted.",
            color: "success",
          });
          this.$router.push({
            name: "AlertManagement"
          })
        } catch (err) {
          console.log(err)
            this.$vs.notify({
              title: "Alert notification",
              text: "Deleting of alert notification failed.",
              color: "danger",
            });
        }
      },
      uploadImage(event, key = "profileImage") {
          // this.$vs.loading();
          let data = new FormData();
          data.append(key, event);
          let config = {
            header: {
              "Content-Type": "image/png",
            },
          };
          axios
          .post("/api/v1/doctors/upload?key=" + key, data, config)
          .then((res) => {
            if (this.imageType === "desktop") {
              this.alert.images.desktop = res.data.Location;
            } else if (this.imageType === "mobile") {
              this.alert.images.mobile = res.data.Location;
            }
            this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
          })
          .catch((err) => {
            this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
            if (err && err.status === 415) {
              this.$vs.notify({
                title: "Upload Failed",
                text: "File Format Not Supported",
                color: "danger",
              });
            } else {
              this.$vs.notify({
                title: "Upload Failed",
                text: "Please try again later.",
                color: "danger",
              });
            }
          });
          
      },
      validateEmail(email) {
        return /(^\w.*@\w+\.\w)/.test(email);
      },
      mapServerErrors(self, err, form = "") {
        let data = err.data.data.details;
        data.map(function (value, key) {
          self.errors.add({
            field: value.context.key,
            msg: value.message.replace(/"/g, ""),
          });
        });
      },
      async initialize(){
        this.$vs.loading();
        await this.getAllBrandsList()
        await this.fetchAllCategoryList()
        this.fetchAlertDetails()
        this.$vs.loading.close();
      }
    },
    created() {
      this.alertId = this.$route.params.alertId
      this.initialize()
    }
  };
  </script>
  